<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/label/articlelabellist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form"  :model="form" label-width="180px">
          <el-form-item :required="true" label="一级分类名称">
            <el-input v-model="form.f_name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="排序">
            <el-input v-model="form.sort"  ></el-input>
          </el-form-item>
          <el-form-item :required="true" label="二级分类">
            <div class="flex" style="margin-bottom:10px;" v-for="(item, index) in form.c_name"
          :key="index">
            <el-input style="margin-right:10px;" v-model="item.name"></el-input>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(index)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              v-if="form.c_name.length - 1 === index"
              @click="add"
            ></el-button>
          </div>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button @click="goNav('/label/articlelabellist')" >取消</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          f_name: '',
          sort: '',
          c_name:[{index:0,name:''}],
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = JSON.parse(JSON.stringify(this.form)) ;
      let arr = [];
      let length = postdata.c_name.length;
      
      if(!postdata.f_name){
        this.$alert("名称必填");
        return;
      }
      console.log(postdata.sort)
       if(postdata.sort === ''){
        this.$alert("排序必填");
        return;
      }
       if(postdata.sort*1 != postdata.sort){
        this.$alert("排序只能为数字");
        return;
      }
      for(let i in postdata.c_name){
        if(!postdata.c_name[i].name){
          _this.$alert("二级分类名不能为空");
          return;
        }
          arr.push(postdata.c_name[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("二级分类名重复");
        return;
      }
      postdata.c_name =  arr;
      axios.put(config.labelCategory,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '添加成功',
                type: 'success'
              });
              _this.goNav('/label/articlelabellist');
            }else{
              if(response.data.message=='一级分类名称已存在'){
              _this.$alert(response.data.message);
              return;
              }
              _this.form = {
                  f_name: '',
                  sort: '',
                  c_name:[{index:0,name:''}],
                }
              _this.$alert(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },// 增加
    add() {
        console.log(this.form.c_name)
        this.form.c_name.push({
          index: this.form.c_name.length,
          name: ""
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.c_name.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.c_name.splice(index, 1);
      }
    },
  },
  created(){
      
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.flex{
    display: flex;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
